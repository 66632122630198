.footer {
  background-color: #f5f4f4; 
  color: #555657; 
  font-size: 1rem; 
  padding-top: 10px; 
  padding-bottom: 10px; 
  margin-top: 0 !important; 
}

.footer p {
  margin-bottom: 5px; 
}

.footer .small {
  font-size: 0.75rem;
  color: #696969;
}

.footer-icon {
  margin-right: 5px; 
  font-size: 0.9rem; 
  vertical-align: middle; 
}

.footer-separator {
  border-top: 1px solid #000000; 
  margin: 0.1rem 0; 
}

@media (max-width: 768px) {
  .footer {
    text-align: center;
  }
}
