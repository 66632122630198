.chatbot-wrapper .chatbot-container {
  max-width: 400px;
  margin: 150px auto;
  border-radius: 20px; /* Hacer que el contenedor sea más redondeado */
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chatbot-wrapper .chatbot-header {
  background-color: #5F86DB; /* Usar el nuevo color base */
  color: white;
  padding: 12px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
}

.chatbot-wrapper .chatbot-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.chatbot-wrapper .chatbot-body {
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.chatbot-wrapper .messages-box {
  height: 380px;
  overflow-y: auto;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f0f8ff; 
  border-radius: 20px;
}

.chatbot-wrapper .message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px; 
}

.chatbot-wrapper .user-message {
  background-color: #fcfcfc; 
  color: rgb(2, 93, 168);
  text-align: right;
  padding: 10px 14px;
  border-radius: 20px;
  max-width: 80%;
  margin-left: auto;
}

.chatbot-wrapper .bot-message {
  background-color: #f1f1f1;
  text-align: left;
  color: #333;
  padding: 10px 14px;
  border-radius: 20px;
  max-width: 80%;
}

/* Animación para la indicación de escritura */
.chatbot-wrapper .typing-indicator {
  display: flex;
  align-items: center;
}

.chatbot-wrapper .dots {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 6px;
}

.chatbot-wrapper .dots span {
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #333;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.chatbot-wrapper .dots span:nth-child(1) {
  left: 0;
  animation-delay: 0s;
}

.chatbot-wrapper .dots span:nth-child(2) {
  left: 9px;
  animation-delay: 0.2s;
}

.chatbot-wrapper .dots span:nth-child(3) {
  left: 18px;
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.chatbot-wrapper .options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.chatbot-wrapper .option-button {
  width: 100%;
  padding: 8px 12px; 
  font-size: 14px;
  border-radius: 20px; 
  text-align: left;
  background-color: #5F86DB; 
  color: white;
  transition: background-color 0.3s, color 0.3s;
  border: none; 
}

.chatbot-wrapper .option-button:hover {
  background-color: #4a6bb5; 
  color: white;
}

.chatbot-wrapper .chatbot-input {
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.chatbot-wrapper button.btn-primary {
  background-color: #5F86DB; 
  border-color: #5F86DB;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
}

.chatbot-wrapper button.btn-primary:hover {
  background-color: #4a6bb5; 
  border-color: #4a6bb5;
}

.chatbot-wrapper .typing-bubbles {
  display: flex;
  align-items: center;
}

.chatbot-wrapper .typing-bubbles span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background: linear-gradient(135deg, #6f86d6, #48c6ef);
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out both;
}

.chatbot-wrapper .typing-bubbles span:nth-child(1) {
  animation-delay: -0.32s;
}

.chatbot-wrapper .typing-bubbles span:nth-child(2) {
  animation-delay: -0.16s;
}

.chatbot-wrapper .typing-bubbles span:nth-child(3) {
  animation-delay: 0;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

.chatbot-wrapper .chatbot-button {
  background-color: #5F86DB; 
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatbot-wrapper .chatbot-button:hover {
  background-color: #4a6bb5; 
}

.chatbot-wrapper .chatbot-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000; 
}

.chatbot-wrapper .chatbot-container {
  position: fixed;
  bottom: 90px; 
  right: 20px;
  width: 350px; 
  max-height: 500px;
  z-index: 2000; 
}


  
.chatbot-button {
  background-color: #5F86DB; 
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chatbot-button:hover {
  background-color: #4a6bb5; 
}


.chatbot-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000; 
}

.chatbot-container {
  position: fixed;
  bottom: 90px; 
  right: 20px;
  width: 350px; 
  max-height: 500px;
  z-index: 2000; 
}

