.vista-usuario-container {
  background-color: #5F86DB;
  color: white;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.beneficiado-text {
  text-align: left;
  padding-left: 18px;
  
}

.Name-DNI {
  font-size: 16px ;
  color: white;
}

.recaptcha-container {
  transform: scale(0.85);
  transform-origin: left center;
}

.benefit-info h2 {
  margin: 0 ;
}

.benefit-card {
  display: flex ;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.benefit-details {
  color: #2c3e50; 
  width: 100% ;
}

.benefit-card h2, .benefit-card h4 {
  color: #2c3e50 ; 
  margin-bottom: 10px ;
}

.benefit-card h4 {
  font-size: 18px ;
}

.Name-DNI {
  color: #ffffff ; 
}

.benefits-list {
  max-height: 400px ; 
  overflow-y: auto ; 
  padding-right: 15px; 
}

.title-faqs {
  font-size: 36px ;  
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff ;
  background-color: #3f81e0 ; 
  padding: 15px;
  border-radius: 8px; 
}

.benefit-card button {
  align-self: flex-end;
  margin-top: 15px ;
}

.custom-accordion .accordion-header {
  background-color: #3f81e0; 
  color: white;
}

.custom-accordion .accordion-body {
  border: 1px solid #3f81e0; 
  border-top: none; 
}

.custom-accordion .accordion-button {
  color: white;
  background-color: #3f81e0;
}

.custom-accordion .accordion-button:not(.collapsed) {
  color: white;
  background-color: #3f81e0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.custom-accordion .accordion-button:focus {
  box-shadow: none;
}

.card-header h5, .card-header h6, .card-header p {
  color: #3f81e0; 
  margin: 0; 
}
.custom-disabled-button{

  color: #ffffff; 
  background-color: #0e3fdf;
  border: none;

}




@media (max-width: 1210px) {
  .search-container .recaptcha-container {
    display: block; 
    width: 100%; 
    margin-top: 20px; 
    transform: scale(1); 
    transform-origin: center; 
  }

  .search-container .col-auto:last-child {
    width: 100%;
  }

  .search-container .col-auto:first-child {
    width: auto; 
  }
}
