.faq-background {
  background-image: url('./CataratasFondo1.jpg'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 50px 0; 
}

.faq-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: rgba(255, 255, 255, 0.8); 
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
}

.title-faqs {
  text-align: center;
  margin-bottom: 40px;
  font-size: 34px;
  color: #ffffff;
  font-weight: bold;
  position: relative;
}

.title-faqs::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: #007bff;
  display: block;
  margin: 10px auto 0;
}

.title-beeg {
  font-size: 28px;
  color: #065cb8;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}

.title-beeg::after {
  content: '';
  width: 40px;
  height: 3px;
  background-color: #0760c0;
  display: block;
  margin: 10px auto 0;
}

.benefit-card {
  transition: transform 0.3s ease;
  background-color: #f8f9fa;
  border-radius: 50% !important;
  padding: 30px; 
  text-align: center !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  border: none;
  height: 150px !important; 
  width: 150px !important; 
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden; 
}

.benefit-card:hover {
  transform: translateY(-5px);
  background-color: #e9ecef;
}

.benefit-icon {
  color: #0760c0;
  margin-bottom: 10px;
  font-size: 30px; 
}

.benefit-card .card-title {
  font-size: 14px; 
  padding: 0 10px; 
  text-align: center;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.text-card{
  color: #007bff;
}

.accordion-button {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.accordion-button:hover {
  background-color: #0056b3;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #0056b3;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-item {
  border: none;
}

.accordion-body {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 15px;
  margin-top: -10px;
}
#generales, #beeg, #jubilados, #discapacitados, #excombatientes {
  scroll-margin-top: 100px;
}

/*pregunta*/

.highlight {
  animation: highlightAnimation 1s ease-in-out;
}

@keyframes highlightAnimation {
  0% {
    color: yellow;
  }
  100% {
    background-color: transparent;
  }
}


.custom-icon {
  color: #007bff;
}
.custom-iconX {
  color: #ff0000;
}

.info-card {
  min-height: 250px; 
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.info-card .col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtext-card{
  margin-bottom: 15px;
}

.info-card span {
  font-size: 14px;
  text-align: center;
}

.custom-icon.selected {
  color: #0056b3;
}

.custom-icon:hover {
  color: #0056b3; 
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}



@media (max-width: 768px) { 
  .benefit-card {
    height: 130px !important; 
    width: 130px !important; 
  }
}

@media (max-width: 576px) { 
  .benefit-card {
    height: 110px !important; 
    width: 110px !important; 
  }
}
