.navbar-brand-margin{
    margin-left: 15px;
}

.ml-auto  {
    margin-left: 15px;
}

.logo-image {
    border-radius: 10px; 
  }