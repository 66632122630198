

body {
  padding-top: 56px; 
}

.separator {
  margin: 20px 0;
}

.image-row {
  margin-left: 15px;
}

.small-image {
  width: 125px; 
  height: auto;
}

#tawkchat-minified-wrapper {
  left: 0 !important;
  right: auto !important;
}


.home-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh; 
  padding: 60px 0;
  background-color: #fff;
  overflow: hidden;
}



.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 2; 
}



.content-container {
  position: relative;
  z-index: 3;
  color: white; 
  padding-top: 100px;
  width: 100%; 
}



.text-container {
  text-align: left;
  padding-right: 20px;
}

.text-container h1 {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.text-container p {
  font-size: 16px;
  margin-bottom: 30px;
}



.image-container {
  position: relative;
  text-align: right;
}

.main-image,
.secondary-image {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.main-image {
  max-width: 52%;
  height: auto;
  position: relative;
  z-index: 2;
  top: -40px; 
  left: 12px;
}

.secondary-image {
  max-width: 45%;
  height: auto;
  position: absolute;
  top: 90px; 
  left: 15px; 
  z-index: 1;
}

.image-container img {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.image-container img:hover {
  transform: scale(1.1); 
}



@media (max-width: 1000px) {
  .home-container {
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
    min-height: 100vh; 
  }

  .text-container {
    text-align: center;
    padding-right: 0;
    margin-bottom: 30px;
  }

  .text-container h1 {
    font-size: 40px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    margin-bottom: 130px;
  }

  .main-image,
  .secondary-image {
    max-width: 45%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 10px;
  }

  .content-container {
    padding-top: 50px;
  }

  .image-row {
    justify-content: center;
    margin-top: 10px;
  }
}



.actividades-wrapper {
  width: 100%; 
  margin-top: 20px; 
}
 




@media (max-width: 1000px) {
  

  

  .image-row {
    margin-top: 10px;
    margin-left: 50;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .image-row img {
    
    max-width: 100px;
  }

  
}
